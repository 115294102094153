<template>
<!-- 套餐卡分组 -->
  <div class="whiteBg">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="table_cont">
      <el-table
        ref="tableDom"  lazy :load="load"
        :header-cell-style="{background:'#EBF2FE'}"
        :data="marketList"
        :key="symbolKey"
        style="width: 100%"
        row-key="mgid"
        :tree-props="{ hasChildren: 'hasChildren'}">
        <el-table-column
          label="序号"
          width="200"
          type="index">
          <template slot-scope="scope">
            <span>{{scope.row.index}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="分组名称"
          prop="mgname">
        </el-table-column>
        <el-table-column
          label="展示"
          prop="mgisok">
          <template slot-scope="scope">
            <el-switch
              v-if="scope.row.mgisok === true"
              :value="scope.row.mgisok"
              active-color="#13ce66"
              inactive-color="#A5B7CC"
              @change="changeSwich(scope.row)">
            </el-switch>
            <el-switch
              v-if="scope.row.mgisok === false"
              :value="scope.row.mgisok"
              active-color="#13ce66"
              inactive-color="#A5B7CC"
              @change="changeSwich(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="排序">
          <template slot-scope="scope">
            <el-button @click="sortHand(scope.row, 1)" type="primary" size="mini" plain
                 style="display: block">
              上移<i class="el-icon-caret-top el-icon--right"></i>
            </el-button>
            <el-button @click="sortHand(scope.row, 2)" type="primary" size="mini" plain
                 style="display: block;margin-left:0px;margin-top: 5px;">
              下移<i class="el-icon-caret-bottom el-icon--right"></i>
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="操作">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" size="small" v-if="scope.row.index" @click="addLevel(scope.row)">新建下级</el-button>
              <el-button type="text" size="small" @click="handEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" size="small" style="color: red;" @click="handDel(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="pageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!-- 分类新增编辑弹窗 -->
    <el-dialog :title="dialogName" :visible.sync="fromShow" center width="600px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="marketfrom" :rules="formRules" ref="marketFrom">
        <el-form-item label="分组名称" prop="mgname">
          <el-input v-model="marketfrom.mgname"  placeholder="请输入分类名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { markgrouGet, marklowGet, markgrouAdd, markgrouUpdate, markgrouDelete, markgrouIsok, marketeOper } from '@/api/market.js'
export default {
  name: 'marketGroup',
  props: ['roleBtn'],
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      marketList: [],
      mgisok: true,
      fromShow: false,
      marketfrom: {
        mgname: '',
        superid: ''
      },
      formRules: {
        mgname: [
          { required: true, trigger: 'blur', message: '分组名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      dialogName: '新增',
      symbolKey:'',	// 不同的key
      msgList: [] //排序
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size
      }
      markgrouGet(params).then(res => {
        if (res.status === 1) {
          if (res.data.data.length > 0) {
            res.data.data.forEach((item, index) => {
              item.index = index + 1
              if (item.isgroup === true) {
                item.hasChildren = true
              } else {
                item.hasChildren = false
              }
            })
            this.marketList = res.data.data
            this.total = res.data.recordcount
          }
          this.symbolKey = Symbol(new Date().toString())
        } else {
          this.marketList = []
          this.total = 0
        }
      })
    },
    // 排序移动
    sortHand (row, type) {
      marketeOper({ mgid: row.mgid, oper: type }).then(res => {
        if (res.status === 1) {
          this.$message.success(res.message)
          this.init()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 加载二级
    load(tree, treeNode, resolve) {
      marklowGet({ mgid: tree.mgid }).then(res => {
        if (res.status === 1) {
          resolve(res.data.data)
        }
      })
    },
    // 点击新增[一级]
    handleAdd () {
      this.fromShow = true
      this.dialogName = '新增'
      this.marketfrom = { mgname: '', superid: '' }
    },
    // 点击新增[二级]
    addLevel (row) {
      this.fromShow = true
      this.dialogName = '新增'
      this.marketfrom.superid = row.mgid
    },
    FromCancel () {
      this.fromShow = false
      this.$refs.marketFrom.resetFields()
    },
    // 点击编辑
    handEdit (row) {
      this.fromShow = true
      this.dialogName = '编辑'
      this.marketfrom = { ...row }
    },
    // 提交[一二级-新增-编辑]
    FromConfirm () {
      this.$refs.marketFrom.validate((valid) => {
        if (!valid) return
        if (this.dialogName === '新增') { // 一二级新增
          const params = {
            mgname: this.marketfrom.mgname,
            superid: this.marketfrom.superid
          }
          markgrouAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.dialogName === '编辑') {
          const params = {
            mgname: this.marketfrom.mgname,
            mgid: this.marketfrom.mgid
          }
          markgrouUpdate(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.FromCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    //是否展示
    changeSwich (row) {
      this.$confirm(`确定执行${!row.mgisok ? "展示" : "不展示"}操作, 是否继续？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markgrouIsok({mgid: row.mgid}).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 删除
    handDel (row) {
      this.$confirm(`确定执行${!row.mgisok ? "展示" : "不展示"}操作, 是否继续？`, "提示", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        markgrouDelete({ mgid: row.mgid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.init()
    },
  }
}
</script>

<style scoped>

</style>
